<template>
  <UserStyles>
  <v-main>
    <v-card
      class="ma-2"
      flat
    >
      <v-row>
        <v-col cols="12" md="8">
          <v-card-title>Компании</v-card-title>
        </v-col>
        <v-col cols="12" md="4" class="text-right">
          <router-link :to="{ name: 'CompanyEdit', params: { id: company.id } }">
            <v-btn color="primary">Изменить</v-btn>
          </router-link>
        </v-col>
      </v-row>
      <v-container>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <p class="account-text-header">Юр. Информация</p>
              <div class="account-text-info">
                <span class="font-weight-medium">Юр. лицо</span>
                {{ company.name }}
              </div>
              <div class="account-text-info">
                <span class="font-weight-medium">Email:</span>
                {{ company.email }}
              </div>
              <div class="account-text-info">
                <span class="font-weight-medium">Страна:</span>
                {{ company.country_label }}
              </div>
              <div class="account-text-info">
                <span class="font-weight-medium">Город:</span>
                {{ company.city }}
              </div>
              <div class="account-text-info">
                <span class="font-weight-medium">Адрес:</span>
                {{ company.address }}
              </div>
              <div class="account-text-info">
                <span class="font-weight-medium">Номер телефона:</span>
                {{ company.phone }}
              </div>
              <div class="account-text-info">
                <span class="font-weight-medium">ОГРНИП:</span>
                {{ company.ogrnip }}
              </div>
              <div class="account-text-info">
                <span class="font-weight-medium">Номер договора:</span>
                {{ company.contract_number }}
              </div>
              <p class="account-text-header">Банковские данные</p>
              <div class="account-text-info">
                <span class="font-weight-medium">Банковский счет:</span>
                {{ company.bank_account }}
              </div>
              <div class="account-text-info">
                <span class="font-weight-medium">Банк:</span>
                {{ company.bank_name }}
              </div>
              <div class="account-text-info">
                <span class="font-weight-medium">БИК:</span>
                {{ company.bank_bik }}
              </div>
              <div class="account-text-info">
                <span class="font-weight-medium">ИНН банка:</span>
                {{ company.bank_inn }}
              </div>
              <div class="account-text-info">
                <span class="font-weight-medium">Пользователи:</span>
                <div v-for="user in company.users" :key="user.id">
                  <router-link class="ms-3" :to="{ name: 'UserView', params: { id: user.id } }">
                    {{ user.first_name }} {{ user.last_name }}
                  </router-link>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-container>
    </v-card>
  </v-main>
    </UserStyles>
</template>

<script>
import account from "@/api/account";
import UserStyles from "@/views/users/UserStyles.vue";

export default {
  components: {UserStyles},
  data() {
    return {
      company: {
        id: '',
        name: '',
        city: '',
      },
    };
  },
  async created() {
    await this.fetchCompany();
  },
  methods: {
    async fetchCompany() {
      try {
        const companyId = this.$route.params.id;
        const response = await account.getCompany(companyId);
        console.log(response.data);
        this.company = response.data;
      } catch (error) {
        console.error('Error fetching company:', error);
      }
    },
  },
};
</script>
